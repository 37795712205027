.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
}

.pagination > li {
  display: inline-block;
  margin: 0 5px;
  border: 1px solid #2774ae;
  border-radius: 5px;
}

.pagination > li > a {
  width: 36px;
  height: 36px;
  display: inline-block;
  padding: 8px 0;
  text-align: center;
  color: #2774ae;
}

.pagination > li.selected {
  background-color: #2774ae;
}

.pagination > li.selected > a {
  color: white;
}
