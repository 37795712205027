header .bp3-navbar-heading img {
  height: 50px;
}

header .bp3-navbar.bp3-dark .bp3-button,
header .bp3-navbar.bp3-dark .bp3-button .bp3-icon {
  color: white;
}

header a.bp3-button span {
  text-transform: uppercase;
  border-bottom: 4px solid transparent;
}

header a.bp3-button.active span {
  font-weight: bold;
  border-bottom: 4px solid #ffd100;
}

header .bp3-popover-wrapper .bp3-button {
  padding-bottom: 9px;
}

header .menu-mobile {
  display: none;
}

@media only screen and (max-width: 1023px) {
  header .menu-desktop {
    display: none;
  }

  header .menu-mobile {
    display: block;
  }
}

@media only screen and (max-width: 400px) {
  header .bp3-navbar-heading img {
    height: 35px;
  }
}
