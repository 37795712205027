.Status {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(#2774ae, #005587);
  color: #005587;
}

.Status .card {
  width: 50%;
  margin: auto;
  padding: 50px;
  border-radius: 5px;
  background-color: white;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .Status {
    background: white;
  }

  .Status .card {
    width: 100%;
    padding: 10px;
    border-radius: 0;
  }
}
