.MessageForm .bp3-card,
.MessageForm .bp3-html-select select,
.MessageForm .bp3-html-select select:hover {
  box-shadow: none;
  border: 1px solid #757575;
  border-radius: 3px;
  background-color: white;
  color: #222222;
}

.MessageForm .bp3-html-select select {
  font-size: 16px;
}

.MessageForm label {
  font-weight: 600;
}

.MessageForm fieldset {
  width: 66%;
  margin: 0;
  padding: 30px 30px 0 30px;
  border: 1px solid #757575;
  border-radius: 3px;
  color: #2774ae;
}

.MessageForm fieldset .bp3-checkbox {
  font-size: 14px;
  vertical-align: top;
}

.MessageForm .bp3-form-group .bp3-control.bp3-checkbox {
  width: 25%;
  margin: 0 0 30px 0;
}

.MessageForm input:not(:checked) ~ .bp3-control-indicator {
  box-shadow: none;
  border: 1px solid #2774ae;
}

.MessageForm input[type="text"] {
  box-shadow: none;
  border: 1px solid #757575;
}

.MessageForm #title {
  width: 33%;
  height: 50px;
}

.MessageForm #body {
  width: 66%;
  height: 50px;
}

.MessageForm .link-options {
  display: flex;
  width: 66%;
}

.MessageForm .link-options > * {
  flex: 1;
}

.MessageForm .link-options > div {
  margin-right: 20px;
}

.MessageForm .url-input,
.MessageForm .screen-input {
  visibility: hidden;
}

.MessageForm .show-url .url-input,
.MessageForm .show-screen .screen-input {
  visibility: visible;
}
