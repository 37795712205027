.ReactTable {
  border: 1px solid #757575;
  border-radius: 0 3px 3px 3px;
  border-collapse: collapse;
}

.ReactTable .status-icon {
  position: relative;
  top: 7px;
  padding-right: 10px;
}

.ReactTable .bp3-icon {
  padding-left: 10px;
  color: #2774ae;
}

.ReactTable .bp3-icon-chevron-up {
  display: none;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
  border-bottom: 1px solid #757575;
}

.ReactTable .rt-thead .rt-tr {
  text-align: left;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-th.-sort-asc {
  background-color: #2774ae;
  color: white;
  box-shadow: none;
}

.ReactTable .rt-thead .rt-th.-sort-desc .bp3-icon,
.ReactTable .rt-thead .rt-th.-sort-asc .bp3-icon {
  color: white;
}

.ReactTable .rt-thead .rt-th.-sort-asc .bp3-icon-chevron-up {
  display: inline-block;
}

.ReactTable .rt-thead .rt-th.-sort-asc .bp3-icon-chevron-down {
  display: none;
}

.ReactTable .rt-thead .rt-th {
  text-transform: uppercase;
  font-weight: 600;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-tbody .rt-td {
  padding: 10px 20px;
  border-right: 1px solid #757575;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #757575;
}

.ReactTable .rt-tbody .rt-td {
  line-height: 50px;
}

.ReactTable .rt-noData {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #222222;
}

.rthfc.-sp .rthfc-th-fixed-left, .rthfc.-sp .rthfc-td-fixed-left {
  border-right-width: 3px;
}
