.ChannelRequest .bp3-card {
  position: relative;
  box-shadow: none;
  border: 1px solid #757575;
  border-radius: 3px;
  background-color: white;
}

.ChannelRequest .status {
  position: absolute;
  right: -1px;
  top: -1px;
  padding: 15px;
  border: 1px solid #2774ae;
  border-radius: 0 3px 0 0;
  background-color: #2774ae;
  color: white;
  font-size: 24px;
}

.ChannelRequest label {
  font-weight: 600;
}

.ChannelRequest .bp3-form-group {
  width: 66%;
}

.ChannelRequest .react-tag {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 7px;
  padding: 5px 10px;
  border: 1px solid #707070;
  border-radius: 3px;
  background-color: #eeeeee;
}
