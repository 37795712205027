body {
  font-family: "proxima-nova", sans-serif;
  color: #222222;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

content {
  position: relative;
  flex: 1 1 auto;
  padding: 85px 15px 15px 15px;
}

footer {
  padding: 15px;
  text-align: left;
}

footer.light {
  color: white;
}

footer.light a {
  color: white;
  text-decoration: underline;
}

h1.bp3-heading {
  font-size: 28px;
}

h2.bp3-heading {
  font-size: 24px;
}

.bp3-heading {
  font-weight: normal;
  color: #005587;
}

.bp3-input {
  color: #222222;
}

.bp3-control input:checked ~ .bp3-control-indicator,
.bp3-control:hover input:checked ~ .bp3-control-indicator,
.bp3-control input:not(:disabled):active:checked ~ .bp3-control-indicator {
  background-color: #2774ae;
}

@media only screen and (max-width: 767px) {
  content {
    padding: 85px 10px 10px 10px;
  }
}

.bp3-menu {
  max-height: 300px;
  overflow-y: scroll;
}
