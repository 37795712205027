.About {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.About .gradient-top {
  background: linear-gradient(#ffffff, #37a3cd, #0063a1);
  text-align: center;
}

.About h1.bp3-heading {
  margin-top: 30px;
  color: #003b5c;
  font-size: 56px;
  line-height: 56px;
  font-weight: 300;
}

.About .request {
  padding: 10px 40px;
}

.About .request a {
  display: inline-block;
  padding: 10px 45px;
  font-size: 24px;
  line-height: 26px;
  color: #005587;
  background-color: #ffd100;
  border: 1px solid #005587;
  border-radius: 10px;
  text-decoration: none;
}

.About .request a:hover {
  color: #ffd100;
  background-color: #005587;
}

.About .hero {
  display: block;
  max-width: 100%;
  margin: 20px auto 5px auto;
}

.About .message-directly {
  display: inline-block;
  margin: 10px 10px 0 10px;
  padding: 5px 10px;
  font-size: 28px;
  line-height: 30px;
  color: #b9004a;
  background-color: #aedded;
}

.About .gradient-bottom {
  background: linear-gradient(#0063a1, #00355a, #b9004a);
  text-align: center;
}

.About .strategy {
  margin-bottom: 65px;
  padding-top: 90px;
}

.About .strategy > * {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
}

.About .strategy > h2 {
  margin: 40px 40px 0 10px;
  font-size: 28px;
  line-height: 30px;
  text-align: left;
  color: white;
}

.About .contact {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.About .contact img {
  margin-left: 15px;
}

.About .contact a {
  font-size: 26px;
  line-height: 32px;
  font-weight: 600;
  color: #ffff00;
}

.About .ucla-made {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 250px;
}

@media only screen and (max-width: 990px) {
  .About .strategy > h2 {
    margin-right: 0;
  }

  .About .contact {
    display: block;
    margin-bottom: 70px;
  }

  .About .contact img {
    position: absolute;
    left: 0;
    bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .About h1.bp3-heading {
    margin-top: 10px;
  }

  .About .request {
    padding: 10px;
  }

  .About .request a {
    width: 100%;
    padding: 10px;
  }

  .About .strategy {
    margin-bottom: 0;
    padding-top: 50px;
  }

  .About .strategy > img {
    margin-bottom: 20px;
  }

  .About .strategy > img:first-child {
    width: 100px;
  }

  .About .strategy > h2 {
    margin-top: 20px;
  }

  .About .contact {
    white-space: pre-wrap;
  }

  .About .ucla-made {
    height: 200px;
  }

  .About footer {
    font-size: 12px;
  }
}

@media only screen and (max-width: 400px) {
  .About .request a {
    font-size: 20px;
    line-height: 22px;
  }

  .About .strategy > h2 {
    max-width: 200px;
    margin-right: 0;
    font-size: 18px;
    line-height: 20px;
  }

  .About .contact {
    margin-bottom: 60px;
  }

  .About .contact img {
    bottom: 60px;
    max-width: calc(100% - 50px)
  }

  .About .ucla-made {
    height: 150px;
  }

  .About footer {
    max-width: calc(100% - 50px);
  }
}
