.styled-tabs .bp3-tab {
  width: 300px;
  padding-top: 10px;
  border: 1px solid #222222;
  border-bottom-width: 0;
  text-align: center;
  font-size: 28px;
  line-height: 65px;
}

.styled-tabs .bp3-tab-list > *:not(:last-child) {
  margin-right: 0;
}

.styled-tabs .bp3-tab:first-child {
  border-top-left-radius: 3px;
}

.styled-tabs .bp3-tab:last-child {
  border-top-right-radius: 3px;
}

.styled-tabs .bp3-tab[aria-selected="false"]:not(:first-child) {
  border-left-width: 0;
}

.styled-tabs .bp3-tab[aria-selected="false"]:not(:last-child) {
  border-right-width: 0;
}

.styled-tabs .bp3-tab[aria-selected="true"] {
  box-shadow: inset 0 10px 0 #106ba3;
  border-color: #2774ae;
  color: #222222;
  font-weight: 600;
}

.styled-tabs .bp3-tab[aria-selected="true"]:hover {
  color: #222222;
}

.styled-tabs .bp3-tab[aria-selected="false"] {
  color: #2774ae;
}

.styled-tabs .bp3-tab[aria-selected="false"]:hover {
  color: #2774ae;
}

.styled-tabs .bp3-tab-panel {
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .styled-tabs .bp3-tab {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    line-height: 40px;
  }
}
