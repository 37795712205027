.AppStats {
  display: flex;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #eeeeee;
}

.AppStats .channel {
  display: inline-block;
  width: 100px;
  padding: 10px 10px 0 10px;
  text-align: center;
}

.AppStats .channel-value {
  font-size: 24px;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .AppStats {
    flex-direction: column;
    text-align: center;
  }
}
