.ChannelRequestForm .bp3-card {
  position: relative;
  box-shadow: none;
  border: 1px solid #757575;
  border-radius: 3px;
  background-color: white;
  color: #222222;
}

.ChannelRequestForm label {
  font-weight: 600;
}

.ChannelRequestForm .bp3-text-muted {
  color: red;
}

.ChannelRequestForm .radio-group {
  margin-bottom: 10px;
}

.ChannelRequestForm .radio-group label.bp3-inline {
  width: 33%;
}

.ChannelRequestForm input[type="text"] {
  height: 50px;
  width: 33%;
  box-shadow: none;
  border: 1px solid #757575;
  font-size: 16px;
}

.ChannelRequestForm textarea.bp3-input {
  height: 50px;
  width: 66%;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: none;
  border: 1px solid #757575;
  font-size: 16px;
}

.ChannelRequestForm .react-tags {
  width: 66%;
  padding: 8px 10px 0 10px;
  border: 1px solid #757575;
  border-radius: 3px;
}

.ChannelRequestForm .react-tags__selected, .ChannelRequestForm .react-tags__search {
  display: inline-block;
}

.ChannelRequestForm .react-tags__search {
  width: 150px;
  margin-bottom: 7px;
}

.ChannelRequestForm .react-tags__search input {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  border: none;
  font-size: 16px;
}

.ChannelRequestForm .react-tag {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 7px;
  padding: 5px 10px;
  border: 1px solid #707070;
  border-radius: 3px;
  background-color: #eeeeee;
}

.ChannelRequestForm .react-tag-delete {
  vertical-align: bottom;
  padding-left: 10px;
  cursor: pointer;
}

.ChannelRequestForm .react-tags__suggestions {
  position: absolute;
  left: 20px;
  bottom: 70px;
  width: calc(66% - 25px);
  height: 130px;
}

.ChannelRequestForm .react-tags__suggestions > ul {
  margin: 10px 0;
  padding: 5px 0;
  border: 1px solid #757575;
  border-radius: 3px;
  background-color: white;
  list-style-type: none;
}

.ChannelRequestForm .react-tags__suggestions > ul > li {
  padding-left: 10px;
}

.ChannelRequestForm .react-tags__suggestions > ul > li:hover,
.ChannelRequestForm .react-tags__suggestions > ul > li:hover mark {
  color: white;
  background-color: #2774ae;
}

.ChannelRequestForm .react-tags__suggestions mark {
  background-color: transparent;
}

.ChannelRequestForm .buttons {
  margin-top: 100px;
  text-align: right;
}

.ChannelRequestForm .buttons button {
  width: 180px;
}

@media only screen and (max-width: 767px) {
  .ChannelRequestForm .bp3-card {
    padding: 0;
    border: none;
  }

  .ChannelRequestForm h2 {
    padding: 0 20px;
    text-align: center;
  }

  .ChannelRequestForm .radio-group label.bp3-inline,
  .ChannelRequestForm input[type="text"],
  .ChannelRequestForm textarea.bp3-input,
  .ChannelRequestForm .react-tags {
    width: 100%;
    margin-right: 0;
  }

  .ChannelRequestForm textarea.bp3-input {
    height: 100px;
  }

  .ChannelRequestForm .react-tag {
    margin-right: 10px;
  }

  .ChannelRequestForm .buttons {
    margin-top: 0;
    text-align: right;
  }

  .ChannelRequestForm .buttons button {
    width: 100%;
  }

  .ChannelRequestForm .bp3-overlay-backdrop {
    background-color: white;
  }

  .ChannelRequestForm .bp3-overlay-content {
    width: 100%;
    padding: 40px 10px;
  }

  .ChannelRequestForm .close-overlay {
    position: absolute;
    right: 16px;
    top: 36px;
    font-size: 17px;
    font-weight: 600;
    color: #2774ae;
  }

  .ChannelRequestForm .react-tags__suggestions {
    left: 0;
    top: 131px;
    width: 100%;
  }

  .ChannelRequestForm .react-tags__suggestions > ul {
    margin: 0;
    padding: 0;
    border: none;
  }

  .ChannelRequestForm .react-tags__suggestions > ul > li {
    margin: 0 10px;
    padding: 5px 10px;
    border-bottom: 1px solid #757575;
  }
}
