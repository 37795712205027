.DateTimePicker > .bp3-form-group > .bp3-form-content {
  display: flex;
}

.DateTimePicker .bp3-input-group .bp3-input {
  z-index: 2;
  width: 150px;
  box-shadow: none;
  border: 1px solid #757575;
  border-radius: 3px;
}

.DateTimePicker .bp3-input-group .bp3-input-action {
  z-index: 3;
}

.DateTimePicker .bp3-datepicker .DayPicker {
  visibility: hidden;
  position: absolute;
  left: -1px;
  top: 0;
  z-index: 1;
  width: 350px;
  padding: 10px;
  border: 1px solid #757575;
  background-color: white;
}

.DateTimePicker .bp3-datepicker .DayPicker-Day:hover,
.DateTimePicker .bp3-datepicker .DayPicker-Day:focus {
  color: #222222;
}

.DateTimePicker.date-picker-visible .bp3-input-group .bp3-input {
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.DateTimePicker.date-picker-visible .bp3-datepicker .DayPicker {
  visibility: visible;
}

.DateTimePicker .bp3-datepicker-navbar {
  top: 10px;
  padding: 0 10px;
}

.DateTimePicker .DayPicker-NavButton {
  width: 35px;
  height: 35px;
  border: 1px solid #2774ae;
}

.DateTimePicker .bp3-datepicker-caption {
  display: block;
  margin-bottom: 20px;
  text-align: center;
}

.DateTimePicker .DayPicker .bp3-html-select select,
.DateTimePicker .DayPicker .bp3-html-select select:hover {
  padding: 0;
  border: none;
  background-color: white;
}

.DateTimePicker .DayPicker .bp3-html-select:first-child select {
  padding-right: 5px;
  direction: rtl;
}

.DateTimePicker .DayPicker .bp3-html-select .bp3-icon {
  display: none;
}

.DateTimePicker .bp3-divider {
  display: none;
}

.DateTimePicker .bp3-datepicker .DayPicker-WeekdaysRow {
  height: 50px;
  border: 1px solid #e9e9f0;
  background-color: #e9e9f0;
  text-transform: uppercase;
}

.DateTimePicker .bp3-datepicker .DayPicker-Month {
  margin: 0;
}

.DateTimePicker .bp3-datepicker .DayPicker .DayPicker-Day {
  width: 50px;
  height: 50px;
  border: 1px solid #757575;
  border-radius: 0;
}

.DateTimePicker .bp3-datepicker .bp3-timepicker {
  position: absolute;
  left: -185px;
  top: 40px;
}

.DateTimePicker.date-picker-visible .bp3-datepicker .bp3-timepicker {
  left: -185px;
}

.DateTimePicker .bp3-timepicker .bp3-timepicker-input-row {
  box-shadow: none;
  border: 1px solid #757575;
}

.DateTimePicker .bp3-timepicker .bp3-timepicker-input {
  color: #222222;
}

.DateTimePicker .bp3-timepicker-ampm-select select {
  width: 60px;
}

.DateTimePicker .label-icon img {
  width: 25px;
  vertical-align: top;
  margin-right: 10px;
}
