.MessagesBarChart {
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #757575;
  border-radius: 5px;
  text-align: center;
}

.MessagesBarChart h3 {
  color: #222222;
}

.MessagesBarChart .bp3-html-select {
  width: 100%;
  margin-bottom: 10px;
}

.MessagesBarChart .bp3-html-select select {
  height: auto;
  padding: 10px 50px 10px 20px;
  box-shadow: none;
  border: 1px solid #757575;
  border-radius: 3px;
  background-color: white;
  color: #2774ae;
  font-size: 16px;
}

.MessagesBarChart .bp3-html-select select:hover {
  box-shadow: none;
  background-color: white;
}

.MessagesBarChart .bp3-html-select .bp3-icon {
  top: 12px;
  right: 20px;
}

.MessagesBarChart .bp3-button-group {
  margin-bottom: 10px;
}

.MessagesBarChart .bp3-button-group .bp3-button,
.MessagesBarChart .bp3-button-group .bp3-button:hover {
  box-shadow: none;
  border: 1px solid #2774ae;
  border-radius: 5px;
  background-color: white;
  color: #2774ae;
  font-size: 12px;
}

.MessagesBarChart .bp3-button-group .bp3-button.bp3-active,
.MessagesBarChart .bp3-button-group .bp3-button.bp3-active:hover {
  box-shadow: none;
  background-color: #2774ae;
  color: white;
}

.MessagesBarChart .arrow {
  width: 36px;
  height: 36px;
  padding: 7px;
  border: 1px solid #2774ae;
  border-radius: 5px;
  color: #2774ae;
  cursor: pointer;
}

.MessagesBarChart .arrow.invisible {
  border-color: transparent;
  color: transparent;
  cursor: auto;
}
