.Dashboard .heading {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
}

.Dashboard .heading .AppDropdown {
  justify-self: start;
  width: 245px;
}

.Dashboard .heading h1 {
  margin-top: 10px;
  padding: 0 10px;
  text-align: center;
}

.Dashboard h2 {
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  .Dashboard .heading {
    display: block;
  }

  .Dashboard .heading .AppDropdown,
  .Dashboard .heading h1,
  .Dashboard .heading .bp3-button {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .Dashboard .charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    column-gap: 20px;
  }
}
