.bp3-button.styled-button {
  height: 50px;
  padding: 10px 30px;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: #2774ae;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #2774ae;
  text-transform: uppercase;
}

#login-button {
  width: 150px;
}

@media only screen and (max-width: 767px) {
  #login-button {
    width: auto;
    padding: 10px 20px;
  }
}

.bp3-button.styled-button:hover {
  background-color: #2774ae;
  color: white;
}

.bp3-button.styled-button .bp3-icon:first-child:last-child {
  margin-right: 5px;
}

.bp3-button.styled-button.styled-button-yellow, #login-button {
  background-color: #ffd100;
  border-color: #005587;
  color: #005587;
}

.bp3-button.styled-button.styled-button-yellow:hover, #login-button:hover {
  background-color: #005587;
  border-color: #005587;
  color: #ffd100;
}

.bp3-button.styled-button.styled-button-yellow .bp3-icon {
  color: #005587;
}

.bp3-button.styled-button.styled-button-yellow:hover .bp3-icon {
  color: #ffd100;
}

.bp3-button.styled-button.styled-button-gray {
  background-color: #eeeeee;
  border-color: #757575;
  color: #222222;
}

.bp3-button.styled-button.styled-button-gray:hover {
  background-color: #222222;
  border-color: #757575;
  color: #eeeeee;
}

.bp3-button.styled-button.styled-button-gray .bp3-icon {
  color: #222222;
}

.bp3-button.styled-button.styled-button-gray:hover .bp3-icon {
  color: #eeeeee;
}
