.AppDropdown.bp3-html-select select {
  height: auto;
  padding: 10px 20px;
  box-shadow: none;
  border: 1px solid #757575;
  border-radius: 3px;
  background-color: white;
  color: #2774ae;
  font-size: 16px;
}

.AppDropdown.bp3-html-select select:hover {
  box-shadow: none;
  background-color: white;
}

.AppDropdown.bp3-html-select .bp3-icon {
  top: 12px;
  right: 20px;
}
